.setting_sub_table {
    width: 700px;
    border: 1px solid #e7e7e7;
}

.setting_sub_table th, .setting_sub_table td {
    color: #59616b !important;
}

.setting_sub_table th {
    color: #59616b !important;
    background-color: #e7e7e7;
}

.checkboxWrap {
    padding: 0 10px 0 0;
}

.selectedList {
    height: 70px;
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding: 0 10px;
    overflow-y: auto;
}

.selectedItem {
    height: 25px;
}

.my_info .error_txt3 {
    position: initial;
}

.server_setting.none_scrolling.check_list table tr th {
    border-bottom: 1px solid #f0f0f0;
}

.server_setting.none_scrolling table tr th {
    background: #fafafa;
    border-right: 1px solid #f0f0f0;
    line-height: 30px;
    padding-left: 12px;
    border-top: 1px solid #f0f0f0;
}

.server_setting.none_scrolling.check_list table tr td {
    border-bottom: 1px solid #f0f0f0;
}

.server_setting.none_scrolling table tr:hover td {
    background: #f3f9ff;
}

.server_setting.none_scrolling table tr td {
    line-height: 15px;
    vertical-align: middle;
    color: #797979 !important;
    padding: 12px;
}

.server_setting.none_scrolling table {
    border-color: grey
}

.server_setting .right_line {
    border-right: 1px solid #f0f0f0;
}

.server_setting .float_l {
    float: left !important;
}

.server_setting .float_r {
    float: right !important;
}

.server_setting .mr10 {
    margin-right: 10px !important;
}

.server_setting .mt10 {
    margin-top: 10px !important;
}

.server_setting .mt25 {
    margin-top: 25px !important;
}

.otp_info{
    color: #363636 !important;
}

.red {
    color: #FF6347;
    line-height: 15px;
    font-size: 13px;
}

.wrapAll
{
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
}